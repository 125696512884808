import React, { useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";
import { HiOutlineBars3BottomRight } from "react-icons/hi2";
import { IoMdArrowDropdown } from "react-icons/io"; // Import dropdown icon
import "./Navbar.css";
import Images from "../contants/Images";

interface NavLink {
  label: string;
  path: string;
  subMenu?: { label: string; path: string }[];
}

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeLink, setActiveLink] = useState<string>("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Handle scrolling effect
  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Memoized navigation links
  const navigationLinks: NavLink[] = useMemo(
    () => [
      { path: "/", label: "Home" },
    { path: "/about-us", label: "About Us" },
    { path: "/resource", label: "Resource" },
    { path: "/services", label: "Services" },
    { path: "/course", label: "Course" },
    { path: "/contact-us", label: "Contact Us" },
      { label: "Log in", path: "/login" },
    ],
    []
  );

  // Toggle menu visibility
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  // Handle link clicks
  const handleClick = (path: string) => {
    setActiveLink(path);
    setIsMenuOpen(false); // Close menu on navigation
  };

  // Toggle dropdown visibility
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
  const closeDropdown = () => setIsDropdownOpen(false);

  return (
    <div className={isScrolled ? "navbar-wrapper shadow-lg" : "navbar-wrapper"}>
      <div className="navbar-container">
        <Link to="/" className="logo">
          <img src={Images.Logo} alt="logo" className="w-[150px] h-[40px]" />
        </Link>

        {/* Menu toggle button for mobile */}
        <div className="menu-toggle" onClick={toggleMenu}>
          {isMenuOpen ? (
            <LiaTimesSolid size={34} color="#E1B04A" className="icon" />
          ) : (
            <HiOutlineBars3BottomRight size={34} color="#004AAc" className="icon" />
          )}
        </div>

        <nav className={`navbar-menu ${isMenuOpen ? "open" : ""} font-sans text-[14px] font-medium`}>
          {navigationLinks.map((link) =>
            link.subMenu ? (
              <div
                key={link.label}
                className="dropdown"
                onMouseEnter={toggleDropdown}
                onMouseLeave={closeDropdown}
              >
                <button className="menu-link flex items-center">
                  {link.label}
                  <IoMdArrowDropdown
                    color="#2AA100"
                    className={`ml-1 transition-transform duration-300 ${
                      isDropdownOpen ? "rotate-180" : "rotate-0"
                    }`}
                  />
                </button>
                {isDropdownOpen && (
                  <div className="dropdown-menu column-layout">
                    {link.subMenu.map((subLink) => (
                      <Link
                        key={subLink.path}
                        to={subLink.path}
                        className="dropdown-link"
                        onClick={() => {
                          closeDropdown();
                          handleClick(subLink.path);
                        }}
                      >
                        {subLink.label}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <Link
                key={link.path}
                to={link.path}
                className={`menu-link ${activeLink === link.path ? "active" : ""}`}
                onClick={() => handleClick(link.path)}
              >
                {link.label}
              </Link>
            )
          )}

          {/* Call-to-action buttons */}
          <div className="">
            <Link to="/hire-talent" onClick={toggleMenu}>
              <button className="font-sans text-[14px] font-medium text-white bg-[#E1B04A] py-[6px] px-[10px] rounded-[5px] hover:bg-[#004AAC]  transition-transform ease-in-out duration-300 hover:scale-110 ">
                Hire Now
              </button>
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navbar;
