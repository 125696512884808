
import Logo from "../../images/logo.svg"
import HeroBg from "../../images/heroBG.svg"
import CustomizedImg from "../../images/customized-img.svg"
import HomeCardImgOne from "../../images/home_card_img_one.png"
import HomeCardImgTwo from "../../images/home_card_img_two.png"
import HomeCardImgThree from "../../images/home_card_img_one.png"
import AboutUsHomeImg from "../../images/about-us-img.svg";
import HeroSectionHomeImgOne from "../../images/hero-img-one.png";
import HeroSectionHomeImgTwo from "../../images/hero-img-two.png";
import HeroSectionHomeImgThree from "../../images/hero-img-three.png";
import RiskManagementImg from "../../images/risk-manage-img.png";
import BondingImg from "../../images/bond-commo-img.png";
import AssuranceImg from "../../images/financial-img.webp";
import FinancialPlaningImg from "../../images/bond-img.webp";
import ConsultingImg from "../../images/hero-img-one.png";
import  InvestmentManagementImg from "../../images/bond-img.png";
import WhoWeAreImg from "../../images/our-service-img.svg";
import ImprovedImg from "../../images/improve-img.png";
import OurTeamImgOne from "../../images/our-team-img-one.png";
import OurTeamImgTwo from "../../images/our-team-img-two.png";
import OurTeamImgThree from "../../images/our-team-img-three.png";
import OurTeamImgFour from "../../images/our-team-img-four.png";
import QualityImg from "../../images/quality-img.png";
import WhyChooseUsImg from "../../images/why-choose.svg"
import TestimonialBgImg from "../../images/testimonial-bg.png"
import TestimonialCardImgOne from "../../images/testimonial-card-one.png"
import TestimonialCardImgTwo from "../../images/testimonial-card-two.png"
import TestimonialCardImgThree from "../../images/testimonial-card-three.png"
import TestimonialCardImgFour from "../../images/testimonial-card-four.png"
import TestimonialCardImgFive from "../../images/testimonial-card-five.png"
import BlogImgOne from "../../images/blog-img-one.png";
import BlogImgTwo from "../../images/blog-img-two.png";
import BlogImgThree from "../../images/blog-img-three.png";
import BlogImgFour from "../../images/blog-img-four.png";
import BlogImgFive from "../../images/blog-img-five.png";
import BlogImgSix from "../../images/blog-img-six.png";











interface Assets {
  [key: string]: string;
}

const assets: Assets = {
  Logo,
  HeroBg,
  CustomizedImg,
  HomeCardImgOne,
  HomeCardImgTwo,
  HomeCardImgThree,
  AboutUsHomeImg,
  HeroSectionHomeImgOne,
  HeroSectionHomeImgTwo,
  HeroSectionHomeImgThree,
  RiskManagementImg,
  BondingImg,
  AssuranceImg,
  FinancialPlaningImg,
  ConsultingImg,
  InvestmentManagementImg,
  WhoWeAreImg,
  ImprovedImg,
  OurTeamImgOne,
  OurTeamImgTwo,
  OurTeamImgThree,
  OurTeamImgFour,
  QualityImg,
  WhyChooseUsImg,
  TestimonialBgImg,
  TestimonialCardImgOne,
  TestimonialCardImgTwo,
  TestimonialCardImgThree,
  TestimonialCardImgFour,
  TestimonialCardImgFive,
  BlogImgOne,
  BlogImgTwo,
  BlogImgThree,
  BlogImgFour,
  BlogImgFive,
  BlogImgSix





 

 
  
};

export default assets;